<template>
  <div class="editOrderBox">
    <iHeader
      backType="blank"
      :headStyle="['msd', 'dsm'].includes($cookies.get('tenant'))?{
          background: '#fff',
          color: '#13161B',
        }: {
          background: 'var(--themeColor)',
          color: '#fff',
        }"
      :backEvent="true"
      @back-event="addPlantBack()"
      :back="true"
    >
    <div slot="headText">
      <span class="head-txt"> 添加需求</span>
    </div>
    
    </iHeader>
    <!-- <div class="publicHead">
      <p>添加需求</p>
    </div> -->
    <div class="contentBox">
      <div class="normalInputRow">
        <MInput
          title="预计总金额"
          noCancel="false"
          must="true"
          type="number"
          v-model="extdata.amount"
          pla="请输入预计总金额"
        ></MInput>
      </div>
    </div>
    <div class="editBtn" @click="saveItem()">
      <span>保存</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      extdata: {
        IsExtra: true,
        SourceCode: "juhe",
        isBudget: true,
        // itemId: "",
        ItemData: {
          ruleVal: 0,
          rule: "",
          departure_time: "",
          passenger_phone: "",
          passenger: {
            uci: "",
            name: "",
            hcpId: "",
          },
          typeTxt: "",
          type: 0,
        },
        amount: "",
        proposalId: "",
        supplierId: "",
      },
      isloding: true,
    };
  },
  created() {
    if (this.$route.query.isPlantform) {
      this.setRouteListeners("addPlantBack");
      this.$root.$eventHub.$on("addPlantBack", (data) => {
        this.addPlantBack();
        this.$root.$eventHub.$off("addPlantBack"); //解绑当前监听，否则下轮监听还会持续叠加
      });
    } else {
      this.redirectRouteListeners(); //记录全局路由轨迹
      this.$root.$eventHub.$on("addPlantBack", (data) => {
        this.addPlantBack();
      });
    }
    console.log(this.$route.query.data)
    let eventData = this.SmartStorage.get("eventData");
    if (eventData && eventData.provinceDictVal && eventData.cityDictVal) {
      this.extdata.ItemData.start_name = eventData.cityDictVal;
    }
    if (this.$route.query.data) {
      this.extdata.amount = this.$route.query.data.BudgetAmount;
      this.extdata.ItemData.start_name = this.$route.query.data.Addr_start;
      this.extdata.proposalId = this.$route.query.data.ProposalId;
      this.extdata.itemId = this.$route.query.data.ItemId;
      this.extdata.supplierId = this.$route.query.data.SupplierId;
    } else {
      this.querySettleSuppliers();
    }
  },
  methods: {
    async querySettleSuppliers() {
      let params = {
        collection: "cfg-icar-netcar-options",
        useCaching: false,
        cachingKey: "",
        filter: {
          tenantCode: this.SmartStorage.get("eventData").tenant_code,
          eventType: {
            $in: [this.SmartStorage.get("eventData").typeDictTxt || this.SmartStorage.get("eventData").typeDictVal, "*"],
          },
          "cities.city": {
            $in: ["上海市", "*"],
          },
        },
        projection: {
          txt: 1,
          code: 1,
          icon: 1,
          supplierId: 1,
        },
      };
      let _this = this;
      this.services.querysettings(params).then(function (res) {
        if (res.success) {
          _this.extdata.proposalId = _this.SmartStorage.get("proposalId");
          res.content.forEach((element) => {
            if (element.code == "juhe") {
              _this.extdata.supplierId = element.supplierId;
            }
          });
        }
      });
    },
    redirectRouteListeners() {
      this.SmartStorage.set("routeListeners", ["closeWebview"]);
    },
    // 退出城市车队页面
    addPlantBack() {
      this.$route.query.isPlantform
        ? this.$router.push("/budgetList")
        : this.closeAppWithReload();
    },
    saveItem() {
      let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if (!reg.test(this.extdata.amount) || this.extdata.amount.length > 5) {
        this.$iToast("请填入正确预估总金额！");
        return false;
      }
      this.isloding = false;
      this.$iDelay(() => {
        this.services.SaveItem(this.extdata).then((res) => {
          if (res.success) {
            this.$iToast("保存成功");
            this.addPlantBack();
          }
          this.isloding = true;
        });
      });
    },
  },
};
</script>  
<style lang="sass" scoped>
@import '@/assets/platform/index.scss'
</style>